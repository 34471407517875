import React from 'react'
import { Link } from "react-router-dom";

const Quicklinks = () => {
  return (
    <>
        <div className="quick-links">
            <h1>Quick Links</h1>

            <div className="content">
                <div><Link to="/home">Home</Link><i class="fa-solid fa-arrow-up"></i></div>
                <div><Link to="/air">Air Freight</Link><i class="fa-solid fa-arrow-up"></i></div>
                <div><Link to="/atmstaging">ATM Staging</Link><i class="fa-solid fa-arrow-up"></i></div>
                <div><a href="#">Company</a><i class="fa-solid fa-arrow-up"></i></div>
                <div><a href="#">Knowledge</a><i class="fa-solid fa-arrow-up"></i></div>
                <div><a href="https://tracking.splendidfreight.com/tracking.php">Online Tracking</a><i class="fa-solid fa-arrow-up"></i></div>
                <div><a href="https://blog.splendidfreight.com">Blog</a><i class="fa-solid fa-arrow-up"></i></div>                                         
            </div>
        </div>
      
    </>
  )
}

export default Quicklinks
