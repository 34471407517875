import React, { useState } from 'react';
import Footer from '../components/footer/Footer'
import Nav from '../components/header/Nav'
import '../components/header/header.css'
import './pages.css'
import './backgroundimage.css'
import ContactForm from '../components/contactform/ContactForm';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Career = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  return (
    <div>
      <div className="header career"> 
        <Nav/> 
        <h1 className='title'>Career</h1>
      </div>
      <div className="body">
      <h1>Career</h1>
      <div className="body-content">
          <p>
            Splendid Freight is a logistics company in Nigeria. we also provide transportation services, 
            warhousing, and logistics to companinies across the country and globally. Splendid Freight offers
            career opportunities in various department such as marketing, sales finance, accounting, human 
            resources, and more. We offer employees a chance to grow and develop their careers with 
            advancement, training, and development opportunities. As a Splendid Freight employee, you 
            will be part of a team of professionals who work hard to help our customers get their packages
            delivered safely and on time every day. you will also have the opportunity to take on new 
            challenges as your career progress.    
          </p>
      </div>

      <button onClick={togglePopup}>Contact Us</button>
     <Modal isOpen={showPopup} onRequestClose={togglePopup} className="modal">
          <ContactForm />
          <ToastContainer />
        </Modal>
      </div>
      <Footer/>
 </div>
  )
}

export default Career