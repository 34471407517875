
import './ContactForm.css';
import '../header/header.css'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ContactForm() {
  const handleClick = () => {
    toast('Sending...', {
      position: "bottom-center",
      autoClose: 2800,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  };
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_w23wubg', 'template_r0ruink', form.current, 'rV6MUwNe0tXKWn_ho')
    
      .then((result) => {
          console.log(result.text);
          toast.success('Sent!', {
            position: "bottom-center",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          e.target.reset();
      }, (error) => {
          console.log(error.text);
      });
  };

  

  return (
    <div>
    <form className="contact-form" ref={form} onSubmit={sendEmail}>
      <h2>Contact us</h2>
      <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="user_name" />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="user_email" />
      </div>
      <div className="form-group">
        <label htmlFor="message">Message:</label>
        <textarea id="message" name="message" />
      </div>
      <button type="submit" onClick={handleClick}>Send Message</button>
      <ToastContainer />
    </form>
    
    </div>
    
  );
}

export default ContactForm;
