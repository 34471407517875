import React from 'react'
import box1 from '../../image/box1.png'
import box2 from '../../image/box2.png'
import box3 from '../../image/box3.png'

const Mainfrieght = () => { 
  return (
    <div className="main-frieght">
      <div className="Text">
        <h1>Splendid Freight International LTD</h1>
        <p>Splendid Freight International Ltd. was founded in 2015 with the genuine intention of providing exceptional and personalized logistic services that contribute to the growth and prosperity of our clients.</p>

<p>We provide comprehensive supply chain solutions across a wide range of industry sectors, and are committed to providing efficient and secure logistics solutions to meet the needs, wants, and demands of our clients.</p>
      </div>

      <div className="box">
        <div className="box-content">
          <div className="img">
            <img src={box1} alt=""/>
          </div>
          <span>Courtesy</span>
          <p>Courtesy is an essential value at our company. It is a value that we hope to instill in all of our employees through training, coaching, and feedback.
          </p>
        </div>

        <div className="box-content">
          <div className="img">
            <img src={box2} alt=""/>
          </div>
          <span>Mission</span>
          <p>To provide our clients complete and specialized logistics solutions for the improvement of their supply chains.
          </p>
        </div>

        <div className="box-content">
          <div className="img">
            <img src={box3} alt=""/>
          </div>
          <span>Vision</span>
          <p>To improve the supply chain for our clients while being a reliable provider to them.
          </p>
        </div>
      </div>

      
    </div>
  )
}

export default Mainfrieght
