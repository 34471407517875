import React from 'react'

const Contactus = () => {
  return (
    <>
    <div className="contact-us">
            <h1>Contact Us</h1>

            <div className="content">
                <div><a href="tel:+2348186516205">+2348186516205</a><i class="fa-solid fa-arrow-up"></i></div>
                <div><a href="tel:+2349168534200">+2349168534200</a><i class="fa-solid fa-arrow-up"></i></div>
                <div><a href="mailto:freight@splendidfreight.com">freight@splendidfreight.com</a><i class="fa-solid fa-arrow-up"></i></div>
                <div><a href="https://instagram.com/splendidfreight?igshid=YmMyMTA2M2Y=" target="_blank">Instagram</a><i class="fa-solid fa-arrow-up"></i></div>
                <div><a href="https://twitter.com/SplendidFreight?t=H2s7oOJtXWa-acW9-Vc5Zw&s=09" target="_blank">Twitter</a><i class="fa-solid fa-arrow-up"></i></div>
                <div><a href="https://web.facebook.com/SplendidFreight" target="_blank">Facebook</a><i class="fa-solid fa-arrow-up"></i></div>
                <div><a href="https://www.linkedin.com/company/splendid-freight/" target="_blank">LinkedIn</a><i class="fa-solid fa-arrow-up"></i></div>         
            </div>
        </div>
    </>
  )
}

export default Contactus
