import './App.css';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Main from './components/main/Main';
import { Helmet } from "react-helmet";
import favicon from './image/favicon.ico' 

function App() { 
  const key = new Date().toISOString();
  return (
    <>
    <Helmet key={key}>
            <title>Splendid Freight | Complete and Specialized
      Logistics Solutions for
      Supply Chain.</title>
      <link rel="icon" type="image/x-icon" href={favicon}></link>
    </Helmet>
      <Header/>
      <Main/>
      <Footer/>
    </>
  );
}

export default App;
