import React, { useState } from 'react';
import Footer from '../components/footer/Footer'
import Nav from '../components/header/Nav'
import '../components/header/header.css'
import './pages.css'
import './backgroundimage.css'
import ContactForm from '../components/contactform/ContactForm';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const Fastmoving = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  return (
    <div>
    <div className="header fastmove"> 
       <Nav/>
       <h1 className='title'>Fast-Moving Consumer Goods</h1>
     </div>
    <div className="body">
     <h1>Fast-Moving Consumer Goods</h1>
     <div className="body-content">
        <p>
          From the production line to the point-of-sale, Splendid Freight through
          its networks can help you
          optimize your supply chain solutions effectively and efficiently to meet the customer’s needs.
        </p>
        <p>
        If you are looking for a reliable supply chain logistics and freight forwarding 
        company to help you with your fast-moving consumer goods logistics needs, contact us today.
        </p>
     </div>

     <button onClick={togglePopup}>Contact Us</button>
     <Modal isOpen={showPopup} onRequestClose={togglePopup} className="modal">
          <ContactForm />
          <ToastContainer />
        </Modal>
    </div>
    <Footer/>
 </div>
  )
}

export default Fastmoving
