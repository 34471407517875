import React from 'react'
import { Link } from "react-router-dom"; 

const Otherservices = () => {
  return (
    <>
    <div className="other-services">
            <h1>Other Services</h1>

            <div className="content">
                <div>   <Link to="/instantquote">Get a Quote</Link><i class="fa-solid fa-arrow-up"></i></div>
                <div><a href="mailto:freight@splendidfreight.com
">Schedule a Pickup</a><i class="fa-solid fa-arrow-up"></i></div>
                <div><a href="mailto:freight@splendidfreight.com">Report Issues</a><i class="fa-solid fa-arrow-up"></i></div>
            </div>
        </div>
      
    </>
  )
}

export default Otherservices
