import React from 'react'
import logo from '../../image/logowhite.png'
import { Link } from "react-router-dom"; 

const About = () => {
  return (
    <>
      <div className="about">
      <Link to="/home">
      <div className="img">
                  <img src={logo} alt=""/>
              </div>
              
            </Link>

        <div className="content">
            <div><Link to="/aboutus">About Us</Link><i class="fa-solid fa-arrow-up"></i></div>
            <div><Link to="/career">Careers</Link><i class="fa-solid fa-arrow-up"></i></div>
            <div><Link to="/cargoweight">Cargo Weight</Link><i class="fa-solid fa-arrow-up"></i></div>
            
            
            
        </div>
      </div>
    </>
  )
}

export default About
